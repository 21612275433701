import {Component, OnInit} from '@angular/core';
import {PartnerDashboardDataService} from '../../Utils/partner-dashboard-data.service';
import {DataService} from '@shared/services/data.service';
import {PartnerDashboardApiService} from '../../Utils/partner-dashboard-api.service';
import {Router} from '@angular/router';
import {PhaseService} from '@shared/services/phase.service';
import {AppDataService} from '@rootmodule/app-data.service';
import {SeoService} from '@shared/services/seo.service';
import {Constants} from '@shared/utils/constants';

@Component({
  selector: 'app-partner-main',
  templateUrl: 'main.component.html'
})

export class PartnerMainComponent implements OnInit {
  constructor(public appDataService: AppDataService,
              public partnerDataService: PartnerDashboardDataService,
              public partnerApiService: PartnerDashboardApiService,
              public dataService: DataService,
              public phaseService: PhaseService,
              private seoService: SeoService,
              private _router: Router) {
    this.seoService.updateMetaTags(Constants.META_TAGS_DESCRIPTION.become_partner);
  }

  public ngOnInit() {
    this.verifyPartner();
    this.getPartnerData();
    this.checkUniqueCodeOnRedirection();
  }

  public verifyPartner() {
    if (!this.partnerDataService.getPartnerUser()) {
      if (this.appDataService.urlParameters.verification_token) {
        this.partnerApiService.verifyPartner(this.appDataService.urlParameters.verification_token)
          .subscribe(
            (data) => {
              this.dataService.resetPartnerViews();
              this.dataService.showHideViews.partnerVerified = true;
              this.dataService.showUniqueCodeScreen = false;
            },
            (error) => {
              this.dataService.resetPartnerViews();
              this.dataService.showHideViews.welcome = true;
            }
          );
        this._router.navigate(['become-a-partner']);
      }
    } else {
      this._router.navigate(['become-a-partner']);
    }
  }

  public getPartnerData() {
    if (this.partnerDataService.getPartnerUser()) {
      this.dataService.showHideLoader(true);
      this.partnerApiService.getPartnerData()
        .subscribe(
          (data) => {
            this.partnerDataService.setPartnerUser(data);
            this.appDataService.handlePartnerRoutes();
            this.dataService.showHideLoader(false);
            if (data.currencies && data.partner_type === 'sell_to') {
              this.partnerDataService.selectedCurrency = data.currencies;
            }
          },
          (error) => {
            this.dataService.showHideLoader(false);
          }
        );
    } else {
      this.dataService.showHideViews.welcome = true;
    }
  }

  public checkUniqueCodeOnRedirection() {
    if (this.appDataService.urlParameters.otp_code && !this.partnerDataService.getPartnerUser()) {
      this.dataService.showHideViews.welcome = true;
      this.phaseService.showSignin = true;
      this.phaseService.openCommonAuth = true;
    }
  }
  
}
